/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Libraries
import parse from 'html-react-parser'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import { USP } from 'components/Identity'

// Elements
import HeroDefault from 'components/elements/HeroDefault'
import HeroDescription from 'components/elements/HeroDescription'
import BlockDefault from 'components/elements/BlockDefault'
import BackgroundDescription from 'components/elements/BackgroundDescription'
import ImageBackground from 'components/elements/ImageBackground'

// Icons
import Arrow from 'img/icon/arrow-right-circled-contrast.inline.svg'

const Intro = styled(BlockDefault)`
  @media(min-width: 992px) {
    margin-top: -200px;
  }

  @media(max-width: 991px) {
    margin-top: -100px;
  }
`

const PartnersBackground = styled.div`
  background-color: #F0F0F0; 
  left: 0;
  top: 0;
  right: 0;

  @media (min-width: 768px) {
    height: 300px;
  }

  @media (max-width: 767px) {
    height: 100%;
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      
      acf {
        banner {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        meta {
          description
          usp {
            title
            description
          }
        }

        partners {
          background_description
          partners {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            description
            button {
              title
              url
              target
            }
          }
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  const [indexOpen, setIndexOpen] = useState(null)

  const setOpen = index => {
    setIndexOpen(index === indexOpen ? null : index)
  }

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <section>
        <HeroDefault small image={acf.banner.image}>
          <HeroDescription className="d-flex align-items-center justify-content-start h-100">
            {parse(acf.banner.title)}
          </HeroDescription>
        </HeroDefault>
      </section>

      <section>
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-7">
              <Intro bigBorderRadius>
                <ParseContent className="py-lg-4 px-lg-4" content={acf.meta.description} />
              </Intro>
            </div>
            <div className="col-lg-5 mt-5 mt-lg-0">
              <div className="mt-lg-n5 ml-lg-n5">
                {acf.meta.usp.map(({ title, description }, index) => (
                  <USP
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    title={title}
                    description={description}
                    index={index}
                    setOpen={setOpen}
                    indexOpen={indexOpen}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-lg-5">
          <BackgroundDescription content={acf.partners.background_description} relative />
        </div>
        <div className="position-relative pt-5">
          <PartnersBackground className="position-absolute" />
          <div className="container py-lg-5 pb-5">
            <div className="row pb-lg-5">
              {acf.partners.partners.map((partner, index) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className="col-md-4 px-xl-5 mb-5"
                >
                  <Partner fields={partner} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

const PartnerImage = styled(ImageBackground)`
  padding-top: 50%;
`

const PartnerDescription = styled(ParseContent)`
  text-align: inherit;
  line-height: ${props => props.theme.font.size.l};
  font-size: ${props => props.theme.font.size.xm};
`

const PartnerLink = styled.a`
  color: ${props => props.theme.color.text.contrast} !important;
  font-size: ${props => props.theme.font.size.xm};
  font-weight: ${props => props.theme.font.weight.s};
  line-height: ${props => props.theme.font.size.l};

  & svg {
    height: 40px;
    width: 40px;
    margin-left: 10px;
  }

  &:hover {
    color: ${props => props.theme.color.text.light};
    text-decoration: underline;
  }
`

const Partner = ({ fields }) => (
  <BlockDefault>
    <PartnerImage src={fields.image} style={{ backgroundSize: 'contain' }} />
    <PartnerDescription className="mt-5" content={fields.description} />
    <PartnerLink
      className="mt-5 d-flex align-items-center justify-content-end"
      href={fields.button.url}
      target={fields.button.target}
    >
      {fields.button.title}
      <Arrow />
    </PartnerLink>
  </BlockDefault>
)

export default PageTemplate
